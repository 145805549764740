<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <el-dropdown placement="bottom-start" @command="handlerOperation">
                <el-button type='primary'>操作</el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item 
                        :command='"gb"' 
                        :disabled='!(form.status != 3 && form.status != 5 && form.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'>关闭</el-dropdown-item>
                    <el-dropdown-item 
                        :command='"bgzxr"' 
                        :disabled='!(form.status != 3 && form.status != 5 && form.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'
                        >指派</el-dropdown-item>
                    <el-dropdown-item 
                        :command='"zycd"' 
                        :disabled='!(form.status != 3 && form.status != 5 && form.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'
                        >重要程度</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-card class="el-main">
            <div slot="header" class="clearfix a-flex-rsbc">
                <span>No.{{ form.sn || '-' }}</span>
                <el-button type="primary" @click="onlyError = !onlyError">{{!onlyError ? "仅查看异常巡检项" : "查看全部巡检项"}}</el-button>
            </div>
            <div style="margin-bottom: 20px" v-if="form.workOrderVoList && form.workOrderVoList.length">
                <div class="a-fw-700 a-fs-14 a-c-blue">产生工单</div>
                <div class="workOrder-content a-flex-rfsfs a-flex-wrap a-mt-20">
                    <span 
                        @click="handleToWorkOrder(item)"
                        class="workOrder-content-item a-c-blue font-point a-fs-14" 
                        v-for="(item,index) in form.workOrderVoList" 
                        :key="index">{{ item.sn }}</span>
                </div>
            </div>
            <div class="a-flex-rfsfs">
                <div class="a-flex-1" style="padding-right: 20px">
                    <div class="a-flex-rfsfs a-flex-wrap">
                        <div class="list-item">
                            <span class="list-item-title">巡检站点</span>
                            <span class="list-item-value">{{ form.stationName || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">巡检类型</span>
                            <span class="list-item-value">{{ form.patrolType | initDic(patrolTypeDic) }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">地址</span>
                            <span class="list-item-value">{{ form.address || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">发布人</span>
                            <span class="list-item-value">{{ form.sendRbacUserCompanyName || '-' }} - {{ form.sendRbacUserName || '-' }} - {{ form.sendRbacUserPhone || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">发布时间</span>
                            <span class="list-item-value">{{ form.sendTime || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">截止时间</span>
                            <span class="list-item-value">{{ form.endTime || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">完成时间</span>
                            <span class="list-item-value">{{ form.completeTime || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">执行人</span>
                            <span class="list-item-value">{{ form.solveRbacUserCompanyName || '-' }} - {{ form.solveRbacUserName || '-' }} - {{ form.solveRbacUserPhone || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">巡检模板</span>
                            <span class="list-item-value">{{ form.patrolTemplateName || '-' }}</span>
                        </div>
                        <div class="list-item">
                            <span class="list-item-title">重要程度</span>
                            <span class="list-item-value">{{ form.importanceLevel | initDic(importanceLevelDic) }}</span>
                        </div>
                    </div>
                    <div class="list-item a-flex-rfsfs">
                        <span class="list-item-title">备注</span>
                        <span class="a-flex-1 a-fw-700" style="word-break: break-all">{{ form.remark || '-' }}</span>
                    </div>
                    <div class="list-item a-flex-rfsfs">
                        <span class="list-item-title">定位打卡</span>
                        <span class="a-flex-1 a-fw-700">{{ form.userAddress || '-' }}</span>
                    </div>
                    <div class="list-item a-flex-rfsfs" v-if="inspectionOfflineDevice && inspectionOfflineDevice.length">
                        <span class="list-item-title" style="color: #F56C6C">存在离线设备</span>
                        <div class="a-flex-rfsfs a-flex-wrap">
                            <el-tag
                                style="margin-right:10px"
                                v-for="(item,index) in inspectionOfflineDevice"
                                :key="index"
                                type="danger"
                                effect="dark">
                                {{ item.deviceCode }}
                            </el-tag>
                        </div>
                    </div>
                    <div>
                        <inspection-feedback 
                            v-for="(item,index) in inspectionFeedBackInfo"
                            :key="index"
                            :title="item.patrolTypeName" 
                            class="a-mt-20" 
                            :onlyError='onlyError'
                            :content='item.questionList'></inspection-feedback>
                    </div>
                </div>
                <div>
                    <div class="a-fw-700 a-fs-14 a-c-blue" style="padding-bottom: 24px">巡检进度</div>
                    <el-timeline style="width: 240px;padding-left:0 !important">
                        <el-timeline-item
                            color="#B9B9B9"
                            v-for="(item, index) in tableData"
                            :key="index"
                            :timestamp="item.createTime">
                            <div class="a-flex-cfsfs">
                                <div class="a-flex-rfsc" style="padding-bottom: 12px">
                                    <span class="a-c-blue a-fw-700" style="padding-right: 10px" v-if='item.newStatus != item.oldStatus'>{{ item.newStatus | initDic(statusDic) }}</span>
                                    <span>{{ item.operateType | initDic(operateTypeDic) }}</span>
                                </div>
                                <div class="a-fs-12 a-flex-cfsfs">
                                    <span>操作人：{{ item.operateRbacUserName || "-" }} {{ item.operateRbacUserphone || "-" }}</span>
                                    <span v-if="item.changeSolveRbacUserPhone">变更后：{{ item.changeSolveRbacUserName || "" }} {{ item.changeSolveRbacUserPhone || "" }}</span>
                                    <span v-if="item.oldImportantLevel != item.newImportantLevel">变更后：{{ item.newImportantLevel | initDic(importanceLevelDic) }}</span>
                                    <span v-if="item.remark" style="word-break: break-all;">操作备注：{{ item.remark }} </span>
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                        <el-empty :image-size='100' description="暂无记录"></el-empty>
                    </div>
                </div>
            </div>
            <div style="min-height: 100px"></div>
        </el-card>
        <div class="a-line-t-e0 footerBox">
            <el-button class="a-ml-24 a-mt-15" @click="cancelSubmit">返回</el-button>
        </div>
        <change-executor ref="changeExecutor" @handlerSuccess='handlerChangeExecutorSuccess' :tableDataItem='currentDatas' type='inspection'></change-executor>
        <change-import-level ref="importLevel" @handlerSuccess='handlerChangeImportantSuccess' :tableDataItem='currentDatas'></change-import-level>
    </div>
</template>

<script>
import inspectionFeedback from '@/views/inspection/child/inspection-feedback'
import changeExecutor from '../workOrder/child/change-executor.vue';
import ChangeImportLevel from '../workOrder/child/change-importLevel.vue';
import { mapState } from 'vuex';
export default {
    components: {
        inspectionFeedback,
        changeExecutor,
        ChangeImportLevel
    },
    data () {
        return {
            id: '',
            questionPaperId: '',
            answerPaperId: '',
            form: {
     
            },
            tableData: [],
            importanceLevelDic: [],
            patrolTypeDic: [],
            statusDic: [],
            operateTypeDic: [],
            inspectionFeedBackInfo: [],
            onlyError: false,//巡检问题 是否仅展示异常项
            inspectionOfflineDevice: [],
            currentDatas: ''
        }
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    mounted () {
        this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
        this.$getDic('patrolType','select').then(res=>{ this.patrolTypeDic = res; })
        this.$getDic('patrolStatus','select').then(res=>{ this.statusDic = res; })
        this.$getDic('PatrolOperate','select').then(res=>{ this.operateTypeDic = res; })
        this.id = this.$route.query.id
        this.questionPaperId = this.$route.query.questionPaperId
        this.answerPaperId = this.$route.query.answerPaperId
        if(this.id) {
            this.getDetail()
            this.getInspectionHis()
            this.getInspectionOfflineDevice()
        }
        if(this.questionPaperId) {
            this.getDetailPaper()
        }
    },
    methods:{
        // 查询详情
        getDetail () {
            this.$Axios._get({
                url: this.$Config.apiUrl.inspectionDetails,
                params: {
                    id: this.id
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form = Object.assign({},res.result.data)
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 查询离线设备
        getInspectionOfflineDevice () {
            this.$Axios._get({
                url: this.$Config.apiUrl.inspectionOfflineDevice,
                params: {
                    patrolId: this.id
                }
            }).then(({data}) => {
                this.inspectionOfflineDevice = data
            })
        },
        getDetailPaper () {
            let reqData = {
                questionPaperId: this.questionPaperId,
            }
            if(this.answerPaperId) {
                reqData = {
                    questionPaperId: this.questionPaperId,
                    answerPaperId: this.answerPaperId
                }
            }
            this.$Axios._get({
                url: this.$Config.apiUrl.inspectionQuestionsList,
                params: {
                    ...reqData
                }
            }).then(({data}) => {
                this.inspectionFeedBackInfo = []
                // 获取答卷种类
                let keys = data.questionList.map(item=>{
                    return item.patrolTypeId
                })
                keys = [...new Set(keys)]
                keys.map(item=>{
                    let patrolType = data.questionList.find(ite=>{
                        return ite.patrolTypeId == item
                    })
                    this.inspectionFeedBackInfo.push({
                        patrolTypeId: item,
                        patrolTypeName: patrolType.patrolTypeName,
                        icon: patrolType.icon,
                        questionList: [],
                        answerNum: 0,
                        errorNum: 0
                    })
                })
                
                data.questionList.map(item=>{
                    let checkImages = item.questionAnswerItemVos && item.questionAnswerItemVos.checkImages ? JSON.parse(item.questionAnswerItemVos.checkImages) : []
                    let isAnomalous = -1//当前问题是否存在异常
					if(item.questionAnswerItemVos) {
						isAnomalous = item.questionAnswerItemVos.answerItems.findIndex(it=>{ //该题是否为异常项
							return it.isEvent
						})
					}
                    this.inspectionFeedBackInfo.map((ite,idx)=>{
                        if(item.patrolTypeId == ite.patrolTypeId) {
                            ite.questionList.push({
                                ...item,
                                checkImagesList: checkImages,
                                description: item.description ? item.description.split('\r\n') : [],
                                answerText: item.questionAnswerItemVos ? item.questionAnswerItemVos.answerItems[0].answerText : '',
						        checkText: item.questionAnswerItemVos ? item.questionAnswerItemVos.checkText : '',
                                isAnomalous: isAnomalous != -1 ? 1 : 0,
                            })
                            if(item.questionAnswerItemVos) {
                                ite.answerNum++
                                let isError = item.questionAnswerItemVos.answerItems.find(it=>{
                                    return it.isEvent
                                })
                                if(isError) {
                                    ite.errorNum++
                                }
                            }
                        }
                    })
                })
            })
        },
        getInspectionHis () {
            this.$Axios._get({
                url: this.$Config.apiUrl.inspectionLog,
                params: {
                    id: this.id
                }
            }).then(({data}) => {
                this.tableData = data.map(item=>{
                    return {
                        ...item
                    }
                })
            })

        },
        handleToWorkOrder (datas) {
            this.$router.push({
                path:'/workOrder/workOrder-info',
                query: {
                    id: datas.id?datas.id:''
                }
            })
        },
        // 修改执行人
        handlerChangeExecutorSuccess (datas) {
            let reqData = {
                patrolId: this.currentDatas.id,
                rbacUserId: datas.solveRbacUser,
                remark: datas.remark
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionChangeSolveUser, 'post')
        },
        handlerChangeImportantSuccess (datas) {
            let reqData = {
                patrolId: this.currentDatas.id,
                ...datas,
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionImportance, 'post')
        },
        // 更多操作
        handlerOperation (commands) {
            let command = commands.split('#')[0]
            let index = commands.split('#')[1]
            switch (command) {
                case 'gb':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认关闭？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        let reqData = {
                            patrolId: this.id,
                            remark: value
                        }
                        this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionPause, 'post')
                    }).catch(err=>{
                        console.log(err);
                    })
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$confirm('是否确认解除挂起?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                       
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.currentDatas = this.form
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.currentDatas = this.form
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        handleSubmitOperate (reqData, apiUrl, method) {
            if(method == 'get') {
                this.$Axios._get({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then((res)=>{
                    this.currentDatas = ''
                    this.getDetail()
                    this.getInspectionHis()
                    this.getInspectionOfflineDevice()
                })
                .catch(err=>{

                })
            }else {
                this.$Axios._post({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then((res)=>{
                    this.currentDatas = ''
                    this.getDetail()
                    this.getInspectionHis()
                    this.getInspectionOfflineDevice()
                })
                .catch(err=>{

                })
            }
            
        },
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.list-item{
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .list-item-title{
        width: 80px;
        text-align: left;
        color: #666666;
        display: inline-block;
    }
    .list-item-value{
        width: 240px;
        text-align: left;
        color: #333333;
        font-weight: 700;
        display: inline-block;
    }
}
.workOrder-content-item{
    background: #F2F6FC;
    padding: 2px 8px;
    border-radius: 4px;
    margin-right: 12px;
}
.send-user{
    background: #53A8FF;
    color: #ffffff;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0 10px 10px 0;
}
.mt64{
    margin-top: 64px
}
.mt21{
    margin-top: 12px
}
.mr80{
    margin-right: 80px;
}
.c79{
    color: #797979
}
.title{
    width: 150px;
    margin-right: 16px;
}
.content{
    width: 240px
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>