<template>
    <div>
        <div class="title">{{ title }}</div>
        <div class="inspection-feedback-content" v-for="(item,index) in tableData" :key="index" v-show="(item.isAnomalous && onlyError) || !onlyError">
            <div class="a-flex-cfsfs">
                <div class="a-flex-rfsc">
                    <span class="inspection-feedback-content-error" v-if="item.isAnomalous">异常</span>
                    <span class="inspection-feedback-content-title">{{ index+1 }}. {{ item.question || '-' }}</span>
                </div>
                
                <div class="a-ml-20 a-mt-10 a-c-666 a-flex-cfsfs" style="font-size: 12px;line-height: 18px" v-if="item.description">
                    <span v-for="(ite,idx) in item.description" :key="idx">{{ ite }}</span>
                </div>
                <div class="a-flex-cfsfs inspection-feedback-content-answer" v-if="item.questionType == 1 || item.questionType == 2">
                    <div class="a-flex-rfsc a-ml-20 a-mt-10 waitAnswerItem" :class="ite.isSelect?'waitAnswerItem-ac':''" v-for="(ite,idx) in item.choiceList" :key="idx">
                        <i class="el-icon-success a-c-blue a-fs-14" v-if="ite.isSelect"></i>
                        <span :class="ite.isSelect?'a-c-blue':''">{{ ite.itemName }}</span>
                    </div>
                </div>
            </div>
            <div class="a-flex-cfsfs a-ml-20" v-if="item.questionType == 3">
                <div class="inspection-feedback-content-rmark ">
                    <span class="inspection-feedback-content-title">巡检反馈：</span>
                    <span class="a-c-blue">{{ item.answerText || '无' }}</span>
                </div>
            </div>
            
            <div class="a-ml-20" v-if="item.isText">
                <div class="inspection-feedback-content-rmark ">
                    <span class="inspection-feedback-content-title">巡检备注：</span>
                    <span class="a-c-blue">{{ item.checkText || '无' }}</span>
                </div>
            </div>
            
            <div class="a-flex-cfsfs a-ml-20 inspection-feedback-content-rmark " v-if="item.checkImages && item.checkImages.length">
                <span class="inspection-feedback-content-title" >巡检反馈图片：</span>
                <div class="a-flex-rfsfs a-flex-wrap a-pb-10" >
                    <el-image 
                        v-for="(ite,idx) in item.checkImagesList"
                        :key="idx"
                        style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0;"
                        :src="ite" 
                        :preview-src-list="item.checkImagesList">
                    </el-image>
                </div>
            </div>
            
            <!-- <el-table 
                v-if="item.selectTableData && item.selectTableData.length"
                :border='true' 
                :data="item.selectTableData" 
                style="width: 100%;" >
                <el-table-column prop="orderId" :label="ite" min-width="120" v-for="(ite,idx) in item.selectTableDataHead" :key="idx">
                    <template slot-scope="{ row }" >
                        <el-image 
                            v-if="isImgs(row.answerItems[0].answerText)"
                            style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0;border: 1px solid #333333"
                            :src="row.answerItems[0].answerText" 
                            :preview-src-list="[row.answerItems[0].answerText]">
                        </el-image>
                        <span v-else>{{ row.answerItems[0].answerText || "-" }}</span>
                    </template>
                </el-table-column>
            </el-table> -->
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: []
            };
        },
        props: {
            title: {
                default: ''
            },
            content: {
                default: ()=>{
                    return []
                }
            },
            onlyError: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            content: {
				deep: true,
				immediate: true,
				handler (val) {
					this.tableData = JSON.parse(JSON.stringify(val))
                    this.tableData.map(item=>{
                        item = {
                            ...item,
                            answerText: item.questionAnswerItemVos ? item.questionAnswerItemVos.answerItems[0].answerText : '',
                            checkText: item.questionAnswerItemVos ? item.questionAnswerItemVos.checkText : '',
                            // selectDevList: selectDevList,
                        }
                        if(!item.questionAnswerItemVos) {
                            this.$set(item, 'questionAnswerItemVos', {})
                        }
                        if(item.questionType == 1 || item.questionType == 2) {
                            item.choiceList.map(ite=>{
                                this.$set(ite, 'isSelect', this.isChoosed(ite.questionItemId,item))
                            })
                        }
                    })
				}
			},
        },
        methods:{
            isImgs (url) {
                let regs = /^https?:\/\/[^\s/$.?#].[^\s]*\.(gif|jpg|jpeg|png|bmp|tiff)$/i
                return regs.test(url)
            },
            isChoosed (itemid, datas) {
                console.log(this.isEmptyObj(datas.questionAnswerItemVos),1);
				if(!this.isEmptyObj(datas.questionAnswerItemVos)) {
					let isSelect = datas.questionAnswerItemVos.answerItems.findIndex(ite=>{
						return ite.chooseItemId == itemid
					})
					if(isSelect != -1) {
						return true
					}else {
						return false
					}
				}else {
					return false
				}
			},
			isEmptyObj (object) {
				let isEmpty = true
				if(Object.prototype.toString.call(object) === '[object Object]'){
					Object.keys(object).forEach(el => {
						if (object[el] !== null && object[el] !== '') {
							isEmpty = false
						}
					})
				}else{
					isEmpty = false
				}
				return isEmpty
			}
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 14px;
        color: #007af0;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .inspection-feedback-content{
        margin-bottom: 12px;
        font-size: 13px;
        .inspection-feedback-content-title{
            color: #333333;
            font-weight: 700;
        }
        .inspection-feedback-content-error{
            padding: 2px 6px;
			border-radius: 20px;
			background: #F56C6C;
			color: #ffffff;
			font-size: 12px;
			margin-right: 6px;
        }
        .inspection-feedback-content-answer{
            // margin: 12px 0;
        }
        .inspection-feedback-content-rmark{
            margin-top: 12px;
        }
        /deep/ .el-table__header tr{
            background: #ffffff !important;
        }
        /deep/ .el-table__header th.el-table__cell{
            background: #ffffff !important;
        }
    }
    .waitAnswerItem{
        border: 1px dashed #999999;
        border-radius: 4px;
        padding: 4px 12px;
    }
    .waitAnswerItem-ac{
        border: 1px dashed #007af0;
    }
</style>